$ng-select-highlight: #007eff !default;
$ng-select-primary-text: #333 !default;
$ng-select-disabled-text: #f9f9f9 !default;
$ng-select-border: #ccc !default;
$ng-select-border-radius: 4px !default;
$ng-select-bg: #ffffff !default;
$ng-select-selected: lighten($ng-select-highlight, 46) !default;
$ng-select-selected-text: $ng-select-primary-text !default;

$ng-select-marked: lighten($ng-select-highlight, 48) !default;
$ng-select-marked-text: $ng-select-primary-text !default;

$ng-select-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
0 0 0 3px rgba(0, 126, 255, 0.1) !default;
$ng-select-placeholder: lighten($ng-select-primary-text, 40) !default;
$ng-select-height: 38px !default;
$ng-select-value-padding-left: 10px !default;
$ng-select-value-font-size: 0.9em !default;
$ng-select-value-text: $ng-select-primary-text !default;

$ng-select-dropdown-bg: $ng-select-bg !default;
$ng-select-dropdown-border: $ng-select-border !default;
$ng-select-dropdown-optgroup-text: rgba(0, 0, 0, 0.54) !default;
$ng-select-dropdown-optgroup-marked: $ng-select-dropdown-optgroup-text !default;
$ng-select-dropdown-option-bg: $ng-select-dropdown-bg !default;
$ng-select-dropdown-option-text: rgba(0, 0, 0, 0.87) !default;
$ng-select-dropdown-option-disabled: lighten($ng-select-primary-text, 60) !default;

.ng-select {
  &.header-p-0 {
    .ng-dropdown-header {
      padding: 0;
    }
  }
}
