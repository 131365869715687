@import "https://fonts.googleapis.com/css2?family=Maven+Pro:wght@700&family=Roboto&display=swap";
@import "https://fonts.googleapis.com/css2?family=Maven+Pro:wght@700&display=swap";
@import "./scss/ng-select.override.scss";
@import "./../node_modules/bootstrap-icons/font/bootstrap-icons.css";
@import "../node_modules/@ng-select/ng-select/scss/default.theme.scss";
$primary: #2385c8;
$secondary: #2fa84f;
$danger: #F33C45;
$success: #14AD5A;
$pumkin: #F77E21;
$white: #FDFDFE;
$default: rgba(9, 100, 207, 0.1);
$default-hover: rgba(9, 100, 207, 0.25);
@import "bootstrap";
@import "./../node_modules/bootstrap/scss/bootstrap";
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
$btn-icon-width: 38px;

printable {
  visibility: hidden;
  display: none;
}

.table {
  --bs-table-bg-state: initial !important;
}

body {
  font-family: "Roboto", sans-serif;
  background: #f8fafb;
  overflow: hidden;
}

header {
  box-shadow: 0px 1px 6px rgba(134, 137, 140, 0.2);
}

.btn-hoverable {
  &:hover {
    background: rgb(0 0 0 / 10%) !important;
  }

  &--active {
    background: rgb(0 0 0 / 30%) !important;
  }
}

.btn-icon {
  width: $btn-icon-width;
  height: $btn-icon-width;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.md-input {
  border-bottom: 1px solid #eee !important;

  &:focus {
    border-bottom-color: #bebebe !important;
  }
}

.d-grid {
  display: grid !important;

  &.has-2-cols {
    grid-template-columns: repeat(2, 1fr);
  }

  &.has-3-cols {
    grid-template-columns: repeat(3, 1fr);
  }
}

.bg-default {
  background-color: $default !important;
}

.btn-default {
  background-color: $default !important;

  &:hover {
    background-color: $default-hover !important;
  }
}

.ng-select {
  &.no-caret {
    .ng-arrow-wrapper {
      display: none;
    }
  }

  &.border-0 {
    .ng-select-container {
      border: none !important;
    }
  }

  &.shadow-none {
    .ng-select-container {
      box-shadow: none !important;
    }
  }
}

input.number::-webkit-outer-spin-button,
input.number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.number[type="number"] {
  -moz-appearance: textfield;
}

.outline-none {
  outline: none !important;
}

.btn {
  border-radius: 0 !important;
  font-family: "Maven Pro", sans-serif !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  letter-spacing: 0.01em !important;

  img {
    vertical-align: bottom !important;
    margin-right: 8px !important;
  }
}

.btn-primary,
.btn-danger,
.btn-success {
  color: #fdfdfe !important;
}

.btn-primary:hover,
.btn-danger:hover,
.btn-success:hover {
  color: #fdfdfe !important;
}

.btn-primary:disabled,
.btn-danger:disabled,
.btn-success:disabled {
  color: #fdfdfe !important;
}

.search-bar {
  input {
    border-radius: 0 !important;
  }

  .input-group-text {
    border-radius: 0 !important;
    border: 1px solid #2385c8 !important;
  }

}

input {
  border-color: #2385c8 !important;
}

.text-normal {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: normal;
}

.pr-0 {
  padding-right: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-1 {
  padding-right: 4px !important;
}

.pl-1 {
  padding-left: 4px !important;
}

.pl-3 {
  padding-left: 24px !important;
}

.card {
  border-color: #2385c8 !important;
  border-radius: 0 !important;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

.d-content {
  display: contents;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.mr-2 {
  margin-right: 8px;
}

.mr-3 {
  margin-right: 16px;
}

.ml-2 {
  margin-left: 8px;
}

.ml-3 {
  margin-left: 16px;
}

.ng-select .ng-select-container {
  border-radius: 0 !important;
  border-color: #2385c8 !important;
}

.table {
  margin-bottom: 0;
}

.table-responsive {
  border-radius: 0px !important;
}

.table-striped {
  thead {
    th {
      --bs-table-bg: #2385C8;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.01em;
      color: #FDFDFE;
      text-align: center;
      height: 40px;
      border: 1px solid #E4E4E5;
    }
  }

  tbody {
    tr {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #192C3E;
      height: 40px;
      cursor: pointer;

      td {
        border: 1px solid #E4E4E5;
      }
    }

    tr:nth-of-type(odd) {
      * {
        --bs-table-bg: #FFFFFF;
      }
    }

    tr:nth-of-type(even) {
      * {
        --bs-table-bg: #F2F5FA;
      }
    }
  }
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-bg-type: initial !important;
}

.modal-content {
  border-radius: 0 !important;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.form-control {
  border-radius: 0 !important;
}

.d-contents {
  display: contents !important;
}

.pointer {
  cursor: pointer;
}

.font-weight-bold {
  font-weight: 700;
}

@media print {
  #btn-screen {
    display: none !important;
    visibility: none !important;
  }

  .rw-succ {
    .col-3 {
      width: 27.5% !important;
    }

    .col-9 {
      width: 72.5% !important;
    }
  }

  .co-1,
  .co-2,
  .co-3,
  .co-4,
  .co-5,
  .co-6,
  .co-7,
  .co-8,
  .co-9,
  .co-10,
  .co-11,
  .co-12 { 
  }

  printable {
    visibility: visible !important;
    display: block !important;
    padding: 0px !important;
  }

  .scroll-content {
    overflow-y: hidden !important;
    height: auto !important;
    padding-right: .25rem!important;
  }
  .noprint {
    visibility: visible !important;
    display: block !important;
  }

  app-root,
  modal-container,
  bs-modal-backdrop {
    visibility: hidden;
    display: none;
  }

  .card {
    border: none !important;
    padding: 0 !important;
  }

  @page {
    font-family: Calibri !important;
    size: auto;
    margin: 0px !important;
  } 
 
  body * {
    font-family: Calibri !important;
    font-size: 13px !important;
    line-height: 16px !important;
    overflow: hidden !important;
  }
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12{
  }
  .product-list{
    .row{
      .col-9{
        padding-right: 0!important;
      }
      .col-3{
        padding-left: 0!important;
      }
    }
  }
  p{ 
    margin-top: 2px!important;
    margin-bottom: 2px!important;
  }
  .line-gap{
    line-height: 0px!important;
  }
  #printSection {
    font-family: Calibri !important;
    padding: 0 !important;
    margin: 0 !important;

    p {
      font-family: Calibri !important; 
    }
  } 
}

.disable-background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1056;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  opacity: 0.8;
  background-color: #000 !important;

  button {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1056;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    opacity: 0.9;

    img {
      width: 100px;
    }
  }
}

.noscroll {
  overflow: hidden;
}

.placeholder {
  background-color: currentcolor !important;
}

b,
strong {
  font-weight: bold;
}

.ngx-result {
  padding: 4px 8px;
  font-size: 12px;
}

// #suggestions{
//   ul{
//     li:not(:last-child){
//       div{
//         .no-result-custom{
//           display: none!important;
//         }
//       }
//     }
//   }
// } 
.table-striped thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.ng-select.ng-select-disabled>.ng-select-container {
  background-color: #e9ecef;
  opacity: 1;

  .ng-arrow-wrapper {
    display: none;
  }
}

.tooltip {
  position: absolute;
}

.tooltip-arrow {
  position: absolute;
}

.search-icon {
  cursor: pointer;
  display: inline-block;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  border: 1px solid #2385C8;
  height: 40px;
}

.pink-panther {
  td {
    --bs-table-bg: #ebe5304d !important;
    --bs-table-bg-type: initial !important;
  }
}

.ned-bg {
  td {
    --bs-table-bg: #ffd6b7 !important;
    --bs-table-bg-type: initial !important;
  }
}

.autocomplete-container .suggestions-container {
  z-index: 999;
}

.pl-2 {
  padding-left: .5rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-3 {
  padding-right: 1rem;
}

.modal-full {
  --bs-modal-width: 100vw;
  margin: 0;

  .modal-content {
    height: 100vh;

    .modal-body {
      height: 100vh;
    }
  }
}

.ml-5 {
  margin-left: 3rem;
}

.select-prescription .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 0;

  .option-prescription {
    padding: 8px 10px;
    display: block;

    &.bg-yellow {
      background-color: #ebe5304d !important;
    }
  }
}

.print-mode {

  printable {
    visibility: visible !important;
    display: block !important;
    padding: 4mm 4mm 4mm 4mm !important;
    width: 100mm !important;
  }

  .scroll-content {
    overflow-y: hidden !important;
    height: auto !important;
  }

  .noprint {
    visibility: visible !important;
    display: block !important;
  }

  app-root,
  modal-container,
  bs-modal-backdrop {
    visibility: hidden;
    display: none;
  }

  .card {
    border: none !important;
    padding: 0 !important;
  }

  @page {
    font-family: Calibri !important;
    size: auto;
    margin: 0px !important;
  }

  body * {
    font-family: Calibri !important;
    font-size: 13px !important;
    padding: .5mm !important;
    margin: 0 !important;
    overflow: hidden !important;
  }

  #printSection {
    font-family: Calibri !important;
    padding: 0 !important;
    margin: 0 !important;

    p {
      font-family: Calibri !important;
      margin-bottom: 0px;
    }
  }

  p {
    // font-weight: bold!important;
  }

  .font-weight-bold {
    // font-weight: bolder!important;
  }

  .co-1,
  .co-2,
  .co-3,
  .co-4,
  .co-5,
  .co-6,
  .co-7,
  .co-8,
  .co-9,
  .co-10,
  .co-11,
  .co-12 {
    padding: 0 !important;
  }
}

.product-list {
  .my-2:first-child {
    margin-top: 0 !important;
  }

  .my-2:last-child {
    margin-bottom: 0 !important;
  }
}

app-customer {
  .customer-banners {
    .carousel-indicators {
      display: none;
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    display: none;
  }

  .redeem-bg {
    position: absolute;
  }

  .redeem-carousel {
    padding-left: 1rem;
    padding-right: 1rem;

    .redeem-col {
      width: 20%;
      padding-bottom: .5rem;
      padding-left: .5rem;
      padding-right: .5rem;

      .card.no-stock {
        opacity: 0.6;

        .box {
          background: rgba(243, 60, 69, 0.15);
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1000;
          left: 0;
          right: 0;
          border-radius: 8px;
        }

        .no-stock-img {
          position: absolute;
          z-index: 10;
          width: 96px !important;
          right: 1.75rem;
          top: 3rem;
        }

      }

      .card {
        padding: 0;
        height: 140px;
        box-shadow: 0px 4px 16px 0px rgba(134, 137, 140, 0.18);
        border-radius: 8px;

        .product-image-outer {
          overflow: hidden;
          height: 126px;
          max-height: 140px;
          position: relative;

          img {
            display: block;
            width: 100%;
            height: auto;
            position: absolute;
            top: .5rem;
          }
        }

        .product-prices {
          position: absolute;
          right: 1rem;
          bottom: 0.25rem;

          .col-12:first-child {
            margin-bottom: -6px;
          }

          .col-12 {
            padding: 0;
            text-align: right;

            .discount-price {
              border-radius: 6px 6px 0px 0px;
              background: $pumkin;
              font-family: Maven Pro;
              font-size: 18px;
              font-weight: 700;
              letter-spacing: 0.18px;
              color: $white;
              display: inline-block;
              padding: 0px 10px 6px 10px;
              margin-right: 3px;
            }

            .product-price {
              font-family: Maven Pro;
              font-size: 24px;
              font-weight: 700;
              letter-spacing: 0.24px;
              border: 2px solid $primary;
              border-radius: 6px;
              color: $primary;
              background: $white;
              display: inline-block;
              padding: 0px 10px;
              position: relative;

              span {
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                letter-spacing: 0.14px;
                vertical-align: top;
              }
            }
          }
        }
      }

      p {
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.16px;
        margin-top: 3px;
        margin-bottom: 3px;
        white-space: pre-wrap;
        word-break: break-word;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
}

.redeem-recipes {
  background: $white;
  margin-top: .5rem;
  border-radius: 8px;
  min-height: 80vh;
}

.strikethrough {
  position: relative;
}

.strikethrough:before {
  position: absolute;
  content: "";
  left: 8.5px;
  top: 40%;
  right: 0;
  border-top: 1.5px solid;
  width: 80%;
  border-color: inherit;

  -webkit-transform: rotate(-10deg);
  -moz-transform: rotate(-10deg);
  -ms-transform: rotate(-10deg);
  -o-transform: rotate(-10deg);
  transform: rotate(-10deg);
}

.carousel-indicators {
  bottom: -.5rem;

  li {
    box-shadow: 0px 1px 6px rgba(255, 255, 255, 0.4);
    width: 8px;
    height: 8px;
    opacity: 1;
    margin: 0 4px !important;
    margin-bottom: 10px !important;
    border-radius: 6px;
    background: rgba(186, 186, 186, 0.50);
  }

  li.active {
    width: 16px;
    height: 8px;
    border-radius: 6px;
    background: #2385C8;
  }

  li::marker {
    content: "";
  }
}

.blur {
  opacity: 0.6;
}

.modal-qris {
  .modal-content {
    -webkit-border-radius: 8px !important;
    -moz-border-radius: 8px !important;
    border-radius: 8px !important;
    width: 37.5vw;

    .modal-body {
      text-align: center;

      p {
        z-index: 2;
        position: relative;
        margin: 2px 0px;
        font-size: 14px;
        letter-spacing: 0.14px;
      }

      p.font-weight-bold {
        font-family: "Maven Pro", sans-serif !important;
        font-size: 36px;
        font-weight: 700;
        letter-spacing: 0.72px;
      }

      p:last-child {
        padding: 0 5rem;
        font-size: 12px;
        margin-top: 29px;
      }

      p:first-child {
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 0.2px;
      }

      p:nth-child(2) {
        .nmid {
          font-size: 14px;
          letter-spacing: 0.14px;
          margin-right: 5.25rem;
        }

        .badge-timer {
          font-size: 14px;
          letter-spacing: 0.14px;
          font-weight: 700;
          border: 1px solid #2385C8;
          color: #2385C8;
          padding: 2px 8px;
          border-radius: 16px;
        }
      }

      .qris {
        z-index: 2;
        position: relative;
      }

      .qris-bg-1,
      .qris-bg-2 {
        position: absolute;
      }

      .qris-bg-1 {
        left: 0;
      }

      .qris-bg-2 {
        right: 0;
        bottom: 0;
        -webkit-border-radius: 0 0 8px 0 !important;
        -moz-border-radius: 0 0 8px 0 !important;
        border-radius: 0 0 8px 0 !important;
      }
    }
  }
}

.bundling-icon {
  cursor: pointer;
}

.bundle-modal {
  .modal-body {
    padding: 8px;
  }
}

.pickup-modal {
  --bs-modal-width: 724px;
}

.d-inline-block {
  display: inline-block;
  padding: 0 !important;
}

.no-border {
  border: 0 !important;
}

.dropdown-x.show {
  transform: translateX(125px) !important;
}

.autocomplete-container {
  box-shadow: none !important;
  border: 1px solid #2385C8 !important;
}

.autocomplete-container .input-container input {
  height: 38px !important;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  display: none !important;
}

.h-100 {
  .ng-select-container {
    height: 100% !important;
  }
}

.ng-select .ng-clear-wrapper {
  display: flex;
  justify-content: end;

  .ng-clear {
    font-size: 24px !important;
    color: #F33C45;
  }
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  padding-right: 0 !important;
}

.prescription-modal {
  --bs-modal-width: 932px;
}

.alert-list {
  padding-left: 1rem;

  li:first-child {
    margin-top: 6px;
  }
}

/* Importing Bootstrap SCSS file. */
@import "./node_modules/bootstrap/scss/bootstrap";

.voucher-list {
  .col-8 {
    padding: 0 !important;
  }

  .col-4 {
    width: 31%;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;

    qrcode {
      padding: 0 !important;

      .barcode-invoice {
        padding: 0 !important;

        img {
          padding: 0 !important;
        }
      }
    }
  }
}